import React, { useState } from "react"
import Layout from "../../layout"
import SEO from "../../seo"
import styled from "styled-components"
import { Link } from "gatsby"

const FaqItem = ({ item }) => {
  const [open, setopen] = useState(false)
  return (
    <>
      <SEO title={item.title} />
      <ItemContainer onClick={() => setopen(!open)}>
        <div className=" main title-bar">
          <h3>{item.title}</h3>
          <h3
            className={
              open ? ` arrow fa-arrow-down` : "  fa-arrow-down open arrow"
            }
          >
            ⌃
          </h3>
        </div>
        <div className="main">
          <div
            className={open ? "open " : "accordion-content "}
            dangerouslySetInnerHTML={{ __html: item.answer }}
          />
        </div>
      </ItemContainer>
    </>
  )
}

export default FaqItem

const ItemContainer = styled.div`
  padding: 10px 0;

  border-bottom: 1px solid var(--darkBlue);

  a {
    color: var(--orange);
    text-decoration: none;
    /*  display: flex;
    align-items: center; */
  }
  .title-bar {
    margin-bottom: 10px;
    h3,
    p {
      margin: 0;
    }
    h3 {
      font-weight: 600;
      font-size: 25px;
    }
  }
  .arrow {
    transform: rotate(180deg);
  }

  .fa-arrow-down {
    transform: rotate(0deg);
    transition: transform 0.3s linear;
  }

  .fa-arrow-down.open {
    transform: rotate(180deg);
    transition: transform 0.3s linear;
  }

  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .open {
    -webkit-transition: max-height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;

    max-height: 300px;
    margin: 0;
    font-size: 20px;
    color: var(--darkBlue);
  }
  .accordion-content {
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    -ms-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    transition: max-height 0.3s;
    margin: 0;
    overflow: hidden;
    max-height: 0;
    font-size: 20px;
    color: var(--darkBlue);
  }
`
