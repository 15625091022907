import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import FaqItem from "../components/template/Faq/faqItem"

const Faq = () => {
  return (
    <Layout>
      <SEO title="FAQ" />
      <Container>
        {/* <BodyWrapper> */}
        <div className="max-width">
          <h1 className="main-header">FAQ</h1>
          <h1>Shipping</h1>
          {Shipping.map((obj) => {
            return <FaqItem item={obj} />
          })}
          <h1>Orders</h1>
          {Orders.map((obj) => {
            return <FaqItem item={obj} />
          })}

          <h1>Baking Instructions</h1>
          {bakingInstructions.map((obj) => {
            return <FaqItem item={obj} />
          })}
          <h1>Product</h1>
          {Product.map((obj) => {
            return <FaqItem item={obj} />
          })}
        </div>
        {/* </BodyWrapper> */}
      </Container>
    </Layout>
  )
}

export default Faq

const Container = styled.div`
  background: var(--beige);
  padding: 70px 0;
  font-family: "FuturaPTCond-Medium", sans-serif;

  .main-header {
    font-size: 3rem;
  }
  h1,
  h3,
  p {
    color: var(--darkBlue);
  }

  h1 {
    font-weight: 900;
    margin: 60px 0 30px;
    text-transform: uppercase;
  }

  .max-width {
    /* max-width: var(--huge); */
    width: 60%;
    margin: 0 auto;

    @media (max-width: 1000px) /* laptopScreen */ {
      max-width: var(--laptopWidth);
      margin: 0 auto;
    }

    @media (max-width: 900px) /* smallLaptop */ {
      max-width: var(--smallLaptopWidth);
      margin: 0 auto;
    }

    @media (max-width: 812px) {
      width: 70%;
      margin: 0 auto;
    }
    @media (max-width: 769px) /* tablet */ {
      max-width: 500px;
      margin: 0 auto;
    }

    @media (max-width: 600px) /* phone */ {
      max-width: 90%;
      margin: 0 auto;
    }
  }
`

const Shipping = [
  {
    title: "How much does shipping cost?",
    answer: "Shipping is FREE  🥳",
    // answer: "Shipping & handling is $9.99 ",
  },

  {
    title: "I just placed an order! When will it ship?",
    answer:
      "Your order will ship via Overnight or 2nd Day Air. Expect between 1-3 business days once the order is placed!",
  },

  {
    title: "How are the products packaged and shipped?",
    answer:
      "Our product assortment comes frozen in a recyclable carton box. They are shipped in an insulated liner with dry ice to keep frozen. If some dry ice remains in the package, DO NOT TOUCH WITH BARE HANDS.",
  },

  {
    title: "What shipping service do you use?",
    answer: "All orders currently ship with UPS.",
  },

  {
    title: "Where can I find my tracking number?",
    answer:
      "Your tracking number will be emailed (or text, if selected) to you as soon as the order ships. Get excited!",
  },

  {
    title:
      "I have an issue with my shipping. Is there someone I can reach out to?",
    answer:
      "Any question, concern or issue you have with your shipping, please reach out to <a href='mailto:hello@savorly.us'>           hello@savorly.us     </a> and we will reply within a few hours.",
  },
]

const Orders = [
  {
    title: "My frozen order arrived melted. Is it ok to eat? ",
    answer:
      "If the package is unfrozen upon arrival, please discard the product and contact us at <a href='mailto:hello@savorly.us'>           hello@savorly.us     </a> with your order number in the subject line. Don’t panic, we will take care of you!",
  },

  {
    title: "How do I apply a discount code to my order? ",
    answer:
      "During the checkout process, click on “Add a discount code or gift card” to enter any discounts or credits.",
  },

  {
    title: "Where else can I buy your products?",
    answer:
      "Our products are available online and in-store. All our products are available <a href='/shop'>here</a>, on savorly.us! In addition, you can use our <a href='/find-us'>store locator </a>to find them in a grocery store near you!",
  },
]

const bakingInstructions = [
  {
    title: "Baking Instructions",
    answer:
      "Please consult our baking/cooking instructions on the back of the product for how to prepare each product. ",
  },

  {
    title: "How long does it take to bake your products?",
    answer:
      "Our products go straight from the freezer to the preheated oven and take about 18-20 minutes (depending on the product) to bake in a conventional oven.",
  },

  {
    title: "How do I get the best result?",
    answer:
      "Place the product in the center of the preheated oven using baking paper. Treat yourself to a nice glass of rosé while they bake.  ",
  },

  {
    title: "Can I cook Savorly in the airfryer?",
    answer:
      "The Gratins can be cooked in the airfryer! We suggest baking them at 390 degrees for 15 minutes in an air fryer.      ",
  },
  {
    title: "Can I cook Savorly in the microwave?",
    answer:
      "No. We do not recommend cooking our products in a microwave. The puff pastry will not rise properly, and you’ll likely to be disappointed!      ",
  },
]

const Product = [
  {
    title: "Do Savorly products contain GMOs? ",
    answer: "All of our products are non-GMO ",
  },

  {
    title: "Do Savorly add any preservatives or artificial flavors?    ",
    answer:
      "No. All our products are with no artificial flavor, color, or preservatives.      ",
  },

  {
    title:
      "What does it mean “may contain” or “made in a facility” with allergens?",
    answer:
      "This indicates that while the product does not contain the allergen as an ingredient, it is produced in a facility where this ingredient is used. Such products may contain trace amounts of allergens that can still be harmful to those who are allergic.       ",
  },
]
